<script>
import brandsService from '@/services/brands.service';
import Brand from '@/data/models/Brand'
import EditLayout from '@/layout/EditLayout'
import libraryService from '@/services/library.service'

export default {
  name: 'SingleBrand',
  components: {
    EditLayout
  },
  data() {
    return {
      item: new Brand,
      photo: {}
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this._get()
	},
  methods: {
    _get() {
      brandsService.getById({ id: this.id })
        .then(res => {
          this.item = res.data
          res.data.library_id && this._getPhoto({ id: res.data.library_id })
        })
    },
    create() {
      brandsService.create({ data: this.item })
      .then(_res => {
        this.$notify({
          message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('brand') }),
          icon: "far fa-bell",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success',
        })
        this.$router.push({ name: 'brands' })
      });
    },
    edit() {
      brandsService.edit({ data: this.item })
      .then(_res => {
        this.$notify({
          message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('brand') }),
          icon: "far fa-bell",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success',
        })
      });
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
  }
}
</script>

<template>
  <EditLayout
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    supports-photo
    :photo="photo"
    @submit="isNew ? create() : edit()"
  >
    <div slot="sidebar-middle">
      <BaseInput
        v-model="item.country"
        :label="$t('country')"
        :placeholder="$t('enterBrandsCountry')"
      />
      <BaseInput
        v-model="item.founding_year"
        :label="$t('founded')"
        type="number"
        :placeholder="$t('enterFoundedYear')"
      />
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
  @import '~@/assets/sass/design.sass'
</style>
