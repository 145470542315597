/* eslint-disable camelcase */
export default class Brand {
  alias;
  country;
  description;
  founding_year;
  library_id;
  name;
  published;

  constructor() {
    this.alias = "";
    this.country = "";
    this.description = "";
    this.founding_year = null;
    this.library_id = null;
    this.name = "";
    this.published = 0;
  }
}
